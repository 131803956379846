<template>
  <v-theme-provider light>
    <base-section
      id="social"
      class="tertiary text-secondary text-center"
      space="56"
    >
      <base-section-heading
        color="transparent"
        title="Folge Uns!"
      />
      <div class="py-4" />
      <v-btn
        icon
        href="https://www.facebook.com/profile.php?id=100015654855506"
        target="_blank"
      >
        <base-icon class="mb-8 mr-10">
          mdi-facebook
        </base-icon>
      </v-btn>

      <v-btn
        icon
        href="https://www.instagram.com/buzbreaking/"
        target="_blank"
      >
        <base-icon class="mb-8 ml-10">
          mdi-instagram
        </base-icon>
      </v-btn>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
